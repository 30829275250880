//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      showMobileSearch: false,
    };
  },
  computed: {
    ...mapState(["mobile"]),
  },
  props: {
    //中文标题
    title: {
      type: String,
      default: "",
    },
    //英文副标题
    titleEnglish: {
      type: String,
      default: "",
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    haveBaseViewLeft: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(["mobile_ToggleState", "mobile_ToggleLeft"]),
    toggleSearch() {
      this.showMobileSearch = !this.showMobileSearch;
    },
    back() {
      // this.$router.back();
      this.$router.push("/home");
    },
    toggleBaseViewLeft() {
      this.mobile_ToggleLeft(!this.mobile.showLeft);
    },
    openMenu() {
      this.mobile_ToggleState({
        action: "menu",
        value: true,
      });
    },
  },
};
